import React, {useEffect, useState} from 'react';
import obj_heb from '../utils/Obj_heb.json';
import {fetchData} from '../utils/fetchData';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import SEO from '../components/SEO';
import CreditsTab from '../components/CreditsTab';
import MoreProductsCarusel from '../components/MoreProductsCarusel';
import Title from '../components/Title';
import PersonalSettingsTab from '../components/PersonalSettingsTab';
import {useNavigate} from 'react-router-dom';
import MyOrdersTab from '../components/MyOrdersTab';
import LobbyPagination from '../components/LobbyPagination';
import RecommendedForYou from '../components/RecommendedForYou';
import Button from '../components/Button';
import classNames from 'classnames';
import GetDibbleCoinModal from '../components/GetDibbleCoinsModal';
import Toast from '../components/Toast';
import 'firebase/firestore';
import {initializeApp} from 'firebase/app';
import {
  fireBaseConfig,
  courierRealtimedbName,
  INTERCOM_APP_ID,
} from '../utils/baseValues';
import {getDatabase, ref, onValue} from 'firebase/database';
import {
  clearActiveOrdersCount,
  updateActiveOrders,
  updateActiveOrdersCount,
} from '../redux/slices/activeOrdersSlice';
import TimeBasedGreeting from '../components/cart/TimeBasedGreeting';
import {icons} from '../assets/constants';
import {clearAddressData, clearCreditCardsData} from '../redux/store';
export default function PersonalArea() {
  let app = initializeApp(fireBaseConfig);
  const db = getDatabase(app);
  const dataRef = ref(db, 'courier');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [TabOptions, setTabOptions] = useState([
    {
      name: obj_heb.balances_and_benefits,
      key: 0,
      tab_name: 'balances_and_benefits',
    },
    {
      name: obj_heb.personal_customization,
      key: 1,
      tab_name: 'personal_preference',
    },
    {name: obj_heb.my_orders, key: 2, tab_name: 'my_orders'},
  ]);
  const [openTabIndex, setOpenTabIndex] = useState(0);
  const [availableDibbleCoin, setAvailableDibbleCoin] = useState(0);
  const [myActiveDiscounts, setMyActiveDiscounts] = useState([]);
  const [likedPRoduct, setlikedPRoduct] = useState([]);
  const [userOrderHistory, setUserOrderHistory] = useState([]);
  const [currentPaginationPage, setCurrentPaginationPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  let PageSize = 10;
  const isMobile = useSelector(state => state.mobile.isMobile);
  let userData = JSON.parse(localStorage.getItem('userData'));

  // grab the query selector
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Access specific query parameters
  const tabName = queryParams.get('tab');
  const activeOrders = useSelector(state => state.activeOrders.activeOrders);

  useEffect(() => {
    let dataObj = {
      request: 'get_my_orders',
      token: userData?.token,
      page: currentPaginationPage,
      page_size: 10,
    };
    fetchData(dataObj)
      .then(response => {
        const data = response;
        if (data) {
          setUserOrderHistory({
            orders: data.orders,
            num_of_pages: data.num_of_pages,
          });
          dispatch(updateActiveOrders(activeOrders));
          for (const element of activeOrders) {
            for (const e of data.orders) {
              if (element.order_id === e.order_id) {
                if (element.courier_phone) {
                  onValue(dataRef, snapshot => {
                    const newData = snapshot.val();
                    let orders = newData[element.courier_phone]?.orders;
                    if (orders) {
                      for (const order of orders) {
                        if (order.order_id === element.order_id) {
                          const index = data.orders.findIndex(object => {
                            return object.order_id === element.order_id;
                          });
                          data.orders[index].textInMinsClient =
                            order.textInMinsClient;
                          setUserOrderHistory(prevState => ({
                            ...prevState,
                            orders: data.orders,
                          }));
                        }
                      }
                    }
                  });
                }
              }
            }
          }
        }
      })
      .catch(error => {
        console.error('Error:', error?.message);
      });
  }, [currentPaginationPage]);

  useEffect(() => {
    // get avible dibble coin
    let userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      navigate('/login');
    }
    let dataObj = {
      request: 'dibblecoin_get_available_credit',
      token: userData?.token,
    };
    fetchData(dataObj)
      .then(response => {
        const data = response;
        if (data.available_credit) {
          setAvailableDibbleCoin(data.available_credit);
        }
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
    // getting user liked items
    let likedProductDataObj = {
      request: 'get_user_liked_products',
      token: userData?.token,
    };
    fetchData(likedProductDataObj)
      .then(response => {
        const data = response;
        if (data.products) {
          setlikedPRoduct(data.products);
        }
      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  }, []);

  useEffect(() => {
    if (tabName === 'personal_preference') {
      setOpenTabIndex(1);
    } else if (tabName === 'my_orders') {
      setOpenTabIndex(2);
    } else setOpenTabIndex(0);
  }, [tabName]);
  const pickTabHandler = item => {
    setOpenTabIndex(item.key);

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('tab', item.tab_name);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const logout = () => {
    if (!userData?.token) return;
    let dataObj = {
      request: 'logout',
      token: userData.token,
    };
    fetchData(dataObj).then(response => {
      localStorage.setItem('userData', null);
      dispatch(clearAddressData());
      dispatch(clearCreditCardsData());
      dispatch(clearActiveOrdersCount());
      window.Intercom('shutdown');
      window.Intercom('reset');
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
      });
      window.Intercom('update', {name: '', email: '', phone: '', userId: ''});
      window.location.reload();
      navigate('/');
    });
  };

  const SEOLoader = () => {
    const PersonalAreaLink = `/personal-area}`;
    return (
      <SEO
        pageName={obj_heb.personal_area}
        content={obj_heb.personal_area}
        link={PersonalAreaLink}
      />
    );
  };

  const titleText = () => {
    const useNameText = userData
      ? userData.first_name + ' ' + userData.last_name
      : '';
    return useNameText;
  };

  return (
    <>
      <SEOLoader />

      <div className="PersonalArea ">
        <div className="PersonalArea_content">
          {isMobile ? (
            <>
              <div
                className="PersonalArea_content__title--mobile_part1"
                style={{
                  textAlign: 'center',
                }}>
                {TimeBasedGreeting()}
              </div>
              <Title
                center={true}
                CustomTitle={
                  <div className="PersonalArea_content__title--mobile">
                    <div className="PersonalArea_content__title--mobile_part2">
                      {titleText()}
                    </div>
                  </div>
                }
                title={titleText()}
              />
            </>
          ) : (
            <Title title={obj_heb.my_area_title} />
          )}
          <div className="PersonalArea_content_section1">
            <div className="PersonalArea_content_section1_tab-headers">
              {TabOptions.map(item => (
                <div
                  key={item.key}
                  className={classNames(
                    'PersonalArea_content_section1_tab-headers_tab_head',
                    openTabIndex == item.key &&
                      'PersonalArea_content_section1_tab-headers_tab_head--selected',
                  )}
                  onClick={() => {
                    pickTabHandler(item);
                  }}>
                  <div
                    className="PersonalArea_content_section1_tab-headers_tab_head_title"
                    style={openTabIndex == item.key ? {fontWeight: '700'} : {}}>
                    {item.name}
                  </div>
                </div>
              ))}
            </div>
            <div className="PersonalArea_content_section1_tab-body">
              {openTabIndex == 0 ? (
                <div className="PersonalArea_content_section1_tab_margin">
                  <CreditsTab
                    availableDibbleCoin={availableDibbleCoin}
                    myActiveDiscounts={myActiveDiscounts}
                    setCreditReqModalIsOpen={setIsOpen}
                  />
                </div>
              ) : openTabIndex == 1 ? (
                <PersonalSettingsTab />
              ) : (
                <>
                  <div className="PersonalArea_content_section1_colum-div">
                    <MyOrdersTab
                      orderData={userOrderHistory}
                      currentPaginationPage={currentPaginationPage}
                      PageSize={PageSize}
                      onPageChange={page => setCurrentPaginationPage(page)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {openTabIndex == 0 && !isMobile && (
            <Button
              text={obj_heb.credit_request}
              onClick={() => setIsOpen(true)}
              textColor="white"
              backgroundColor="var(--dibble-yellow)"
              className="PersonalArea_content_credit_req"
            />
          )}
          {openTabIndex == 1 && isMobile && (
            <div
              onClick={() => {
                logout();
              }}
              className="PersonalSettingsTab__logOut">
              <div className="PersonalSettingsTab__logOut__titleBox">
                <span className="PersonalSettingsTab__logOut__titleBox__textRed">
                  {obj_heb.exit}
                </span>
                <span>{obj_heb.exitText}</span>
              </div>
              <img
                className="PersonalSettingsTab_item--title_arrowhead_img"
                src={icons.red_left_arrow_head}
                alt="leftArrowHead"
              />
            </div>
          )}
          {openTabIndex == 2 &&
            (!isMobile ? (
              <LobbyPagination
                className="pagination-bar"
                currentPage={currentPaginationPage}
                totalPageCount={userOrderHistory?.num_of_pages || 0}
                siblingCount={1}
                pageSize={PageSize}
                onPageChange={page => setCurrentPaginationPage(page)}
              />
            ) : (
              <div className="PersonalArea_content--allOrders">
                <div
                  className="PersonalArea_content--allOrders_text"
                  onClick={() => {
                    navigate('/AllOrders');
                  }}>
                  {obj_heb.toAllOrders}
                </div>
              </div>
            ))}
          <div className="PersonalArea_content_liked_products">
            {likedPRoduct?.length ? (
              <MoreProductsCarusel
                header={obj_heb.product_i_liked}
                headerNavigation={'/likedProducts'}
                productList={
                  likedPRoduct?.map(item => {
                    return {
                      imgLink: item.image,
                      product_id: item.product_id,
                      txtBeforeThePrice: obj_heb.priceStartAt,
                      productOnSale: false,
                      salePrice: 0,
                      price: item.price,
                      title: item.name,
                    };
                  }) || []
                }
                num={5}
              />
            ) : (
              <RecommendedForYou num={5} />
            )}
          </div>
        </div>
        <GetDibbleCoinModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          closeCallback={() => setIsOpen(false)}
          setIsFinish={setIsFinish}
        />
        {isFinish && <Toast text={obj_heb.creditLineSuccessMessage} />}
      </div>
    </>
  );
}
